<template>
  <div class="container" v-if="questionList.length">
    <van-progress
      style="margin-top: 16px"
      :pivot-text="pivotText"
      color="#00c4b3"
      track-color="#00c4b320"
      :percentage="percentage"
    />
    <div class="main">
      <chat
        :chatContent="chatContent"
        :showBack="questionIndex > 0"
        @onClickBack="onClickBack"
        @onClickOption="onClickOption"
        @onClickNext="onClickNext"
      ></chat>
    </div>
  </div>
</template>

<script>
import chat from "@/views/components/questionnaire/chat.vue";

import { getQuestionsToBusiness, getBusinessAssessmentResults } from "@/api";
export default {
  components: {
    chat,
  },
  data() {
    return {
      sex: null,
      marital: null,
      questionIndex: 0,
      questionList: [],
      answers: [],
    };
  },

  computed: {
    chatContent() {
      return this.questionList[this.questionIndex];
    },
    pivotText() {
      return `${this.questionIndex + 1}/${this.questionList.length}`;
    },
    percentage() {
      return ((this.questionIndex + 1) / this.questionList.length) * 100;
    },
  },
  watch: {
    questionIndex(val) {
      if (val && val + 1 >= this.questionList.length) {
        this.getResult();
      }
    },
  },
  mounted() {
    this.$setTitle("健康评估");
    this.getQuestionList();
  },
  methods: {
    async getQuestionList() {
      const res = await getQuestionsToBusiness();
      if (res.success) {
        this.questionList = res.data;
        this.showBaseQuestion = false;
      }
    },
    async getResult() {
      let list = []
       this.questionList.forEach((item) => { 
        item.options.forEach((option) => {
          if (option.checked) {
            list.push({
              questionId: item.id,
              selectedValue: option.value,
            });
          }
        });
      
      });
      this.answers = list
      console.log(this.answers);
      const res = await getBusinessAssessmentResults({
        answers: this.answers,
      });
      if (res.success) {
        localStorage.setItem("questionnaireBusinessResult", JSON.stringify(res.data));
        this.$router.replace("/questionnaire-recommend");
      }
    },
    onClickOption(option) {
      const question = this.questionList[this.questionIndex];
      const isMuilt = question.isMuilt;
      if (isMuilt) {
        const index = question.options.findIndex(
          (item) => item.value === option.value
        );
        const checked = option.checked ? false : true;
        this.$set(question.options[index], "checked", checked);
      } else {
        question.options.forEach((item) => {
          item.value === option.value
            ? (item.checked = true)
            : (item.checked = false);
        });
      }
      if (!isMuilt && this.questionIndex < this.questionList.length - 1) {
        //单选题直接下一题
        this.questionIndex++;
      }
    },
    onClickNext() {
      if(this.questionIndex < this.questionList.length - 1){
        this.questionIndex++;
      }
    },
    onClickBack() {
      if (this.questionIndex <= 0) {
        return;
      }
      const question = this.questionList[this.questionIndex]
      question.options.forEach((item) => {
        item.checked = false;
      });
      this.questionIndex--;
    },
  },
};
</script>


<style scoped>
.container {
  display: flex;
  flex-direction: column;
  background-color: #fff;
}
.main {
  flex: 1;
  margin-top: 50px;
  padding: 0 15px 20px 15px;
  overflow-y: scroll;
}
</style>