<template>
  <div class="chat">
    <img class="avatar" src="@/assets/chat-avatar.png" alt="" />
    <div class="question">
      {{ chatContent.question }}
    </div>
    <div class="button-container">
      <div
        class="button"
        :class="[item.checked ? 'button-primary' : 'button-default']"
        :style="{width: chatContent.options.length > 2 ? '100%':'48%'}"
        v-for="(item, index) in chatContent.options"
        :key="item.value"
        @click="onClickOption(item, index)"
      >
        <span class="bold" style="width: max-content">{{ item.name }}</span>
      </div>
    </div>
    <div class="question-tips" v-if="chatContent.tips">
      {{ chatContent.tips }}
    </div>
    <div class="footer">
      <div class="footer-button-container">
        <div class="button-back" v-if="showBack" @click="onClickBack">
          返回上一题
        </div>
        <div class="button-back" v-if="chatContent.isMuilt" @click="next">
          进入下一题
        </div>
      </div>
      <div class="tips">若中途退出，需要重新开始评估</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    chatContent: {
      type: Object,
      default: () => {},
    },
    showBack: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onClickOption(item) {
      this.$emit("onClickOption", item);
    },
    onClickBack() {
      this.$emit("onClickBack");
    },
    next() {
      if (!this.$props.chatContent.options.some((item) => item.checked)) {
        return this.$toast("请至少选择一个选项！");
      }
      this.$emit("onClickNext");
    },
  },
};
</script>

<style scoped>
.chat {
  position: relative;
  height: 100%;
}
.avatar {
  display: block;
  width: 80px;
  height: 80px;
}
.question {
  margin-top: 15px;
  font-size: 14px;
  line-height: 20px;
  font-weight: bold;
  color: #2c2f37;
  white-space: pre-wrap;
}

.button-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 30px;
  padding: 0 20px;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  padding: 0 10px;
  height: 50px;
  background: #eff1f1;
  border-radius: 50px;
  font-size: 16px;
  font-weight: bold;
  color: #2c2f37;
}


.button-default {
  background: #eff1f1;
}

.button-primary {
  background-color: #00c4b3;
  color: #fff;
}

.footer-button-container {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.button-back {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  color: #878d99;
  background-color: #fff;
  border: 1px solid #EDEFF0;
  height: 50px;
  border-radius: 50px;
  font-size: 16px;
  font-weight: bold;
  color: #878D99;
}

.question-tips {
  margin-top: 15px;
  font-size: 12px;
  color: #878d99;
  white-space: pre-wrap;
}

.footer {
  margin-top: 50px;
  width: 100%;
}

.tips {
  margin-top: 40px;
  font-size: 14px;
  color: #878d99;
  text-align: center;
  padding-bottom: 0;
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
}
</style>